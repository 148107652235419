import {Broadcast, Team, Location, useApiCall, useApiEndpoint} from "@jane/lib/src/api"
import { Button } from "@jane/lib/src/components/form/Button"
import React, {ChangeEvent, FC, useCallback, useEffect, useMemo, useState} from "react"
import {config} from "../config";
import {useTeamData} from "../resources/TeamContext";
import {usePermissions} from "../resources/PermissionsHook";
import {useTeamMap} from "./team-select";
import { Autocomplete } from "@jane/lib/src/components/form/Autocomplete";
import { Input } from "@jane/lib/src/components/form/Input";
import { Callout } from "@jane/lib/src/components/content/Callout";
import {faCircleNotch, faWarning} from "@fortawesome/free-solid-svg-icons";
import { SectionHeader } from "@jane/lib/src/components/content/SectionHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


interface BroadcastFormProps {
  broadcast: Partial<Broadcast>
  // eslint-disable-next-line no-unused-vars
  onBroadcastSubmitted: (data: Partial<Broadcast>) => Promise<void>
  step: number
  // eslint-disable-next-line no-unused-vars
  onStepChange: (step: number) => void
}

export const BroadcastsForm: FC<BroadcastFormProps> = (props) => {
  const [audience, setAudience] = React.useState<"employees"|"clients">(props.broadcast.target_type ?? "employees")
  const [targetId, setTargetId] = React.useState<number|null>(props.broadcast.target_id ?? null)
  const [subject, setSubject] = React.useState<string>(props.broadcast.subject ?? "")
  const [message, setMessage] = React.useState<string>(props.broadcast.message ?? "")

  const readyToSubmit = targetId !== null && subject.length > 0 && message.length > 0

  const [isSaving, setIsSaving] = useState(false)
  const save = () => {
    if (!readyToSubmit) {
      console.warn('Not ready to submit')
      return
    }
    setIsSaving(true)
    props.onBroadcastSubmitted({
      message: message,
      subject: subject,
      status: "draft",
      target_id: targetId,
      target_type: audience,
    }).then(() => {
      setIsSaving(false)
    })

  }

  return <div className={"bg-gray-50 rounded-lg py-3 px-6 my-3"}>

    <div className={"flex items-stretch justify-evenly"}>
      <button className={"w-64 flex flex-col hover:bg-gray-100 disabled:hover:bg-transparent py-3 rounded items-center justify-center"} onClick={() => props.onStepChange(1)}>
        <div className={`${props.step === 1 ? "bg-blue-500 text-white" : "bg-white text-blue-500"} w-10 h-10 flex justify-center items-center rounded-full mb-2`}>1</div>
        Publiek en bericht
      </button>
      <button className={"w-64 flex flex-col hover:bg-gray-100 disabled:hover:bg-transparent py-3 rounded items-center justify-center"} disabled={props.step < 2} onClick={() => props.onStepChange(2)}>
        <div className={`${props.step === 2 ? "bg-blue-500 text-white" : "bg-white text-blue-500"}  w-10 h-10 flex justify-center items-center rounded-full mb-2`}>2</div>
        Bijlage toevoegen
      </button>
      <button className={"w-64 flex flex-col hover:bg-gray-100 disabled:hover:bg-transparent py-3 rounded items-center justify-center"} disabled onClick={() => props.onStepChange(3)}>
        <div className={`${props.step === 3 ? "bg-blue-500 text-white" : "bg-white text-blue-500"}  w-10 h-10 flex justify-center items-center rounded-full mb-2`}>3</div>
        Bericht versturen
      </button>
    </div>

    <hr className={"border-gray-200 -mx-6"}/>

    {props.step === 1 && <>
      <SectionHeader>Publiek</SectionHeader>
      <div className={"flex flex-row space-x-8"}>
        <div className={"border-r w-1/2 pr-4 border-gray-200"}>
          <div className={"flex flex-row space-x-2 w-64"}>
            <label className={"hover:bg-gray-200 cursor-pointer p-2 rounded"}>
              <input type="radio" name={"audience"} value={"employees"} checked={audience === "employees"} onChange={() => {setAudience('employees'); setTargetId(null) }} /> Medewerkers
            </label>
            <label className={"hover:bg-gray-200 cursor-pointer p-2 rounded"}>
              <input type="radio" name={"audience"} value={"clients"} checked={audience === "clients"} onChange={() => {setAudience('clients'); setTargetId(null)}} /> Clienten
            </label>
          </div>
          <div className={"mb-4"}>
            {audience === "employees" ? <>
              <label className={"flex flex-col w-96"}>
                <span>Team</span>
                <TeamSelect value={targetId} onChange={(team) => setTargetId(team)} />
              </label>
              {targetId === null && <>
                <Callout color={'warning'} icon={faWarning}>Selecteer eerst een team om verder te gaan</Callout>
              </>}
            </> : <>
              <LocationSelect value={targetId} onChange={(location) => setTargetId(location)} />
              {targetId === null && <>
                <Callout color={'warning'} icon={faWarning}>Selecteer eerst een locatie om verder te gaan</Callout>
              </>}
            </>}

          </div>
        </div>
        <div className={"flex-1 text-gray-700"}>
          <p>
            Wanneer je een publicatie wilt versturen naar medewerkers, kan dat op basis van een team. Wanneer je een publicatie wilt versturen naar clienten, kan dat op basis van een locatie.
          </p>
          <p>
            In beide gevallen is het mogelijk om een publicatie te verzenden naar iedereen.
          </p>
        </div>
      </div>


      <SectionHeader>Bericht</SectionHeader>

      <Input label={"Onderwerp"} value={subject} onChange={setSubject} type={'text'} />

      <Input label={"Bericht"} value={message} onChange={setMessage} type={'textarea'} placeholder={'Schrijf hier je bericht...'} />
      <Button size={'sm'} type={'primary'} disabled={!readyToSubmit || isSaving} onClick={() => save()} text={'Naar bijlage toevoegen'} />
    </>}
    {props.step === 2 && props.broadcast.id && <>
      {props.broadcast.attachment_name && <div className={"flex mb-4 px-4 py-3 border border-gray-100 rounded items-center"}>
        <span className={"h-4 w-4 mr-3 font-light text-gray-600 bi bi-paperclip"} />
        <div className={"flex-1"}>
          { props.broadcast.attachment_name }
        </div>
        <button onClick={() => props.onBroadcastSubmitted({attachment_path: null})} className={"hover:bg-gray-100 text-gray-600 hover:text-red-600 h-6 w-6 flex items-center justify-center rounded-full"}><span className={"font-light bi bi-x-circle-fill"} /></button>
      </div>}
      <div className={'mb-4'}>
        <AttachmentUpload broadcastId={props.broadcast.id} onUpload={(path) => props.onBroadcastSubmitted({attachment_path: path})} />
      </div>
      <Button size={'sm'} type={'primary'} onClick={() => props.onStepChange(3)} text={'Doorgaan naar versturen'} />
    </>}
    {props.step === 3 && props.broadcast.id && <>
      <BroadcastSendConfirmation broadcast={props.broadcast} onConfirm={() => props.onBroadcastSubmitted({})} />
    </>}
  </div>

}

// eslint-disable-next-line no-unused-vars
function AttachmentUpload(props: { broadcastId: number, onUpload: (path: string) => Promise<void> }) {
  const [isLoading, setIsLoading] = useState(false)
  const {broadcasts} = useApiCall(config)
  function upload(event: ChangeEvent<HTMLInputElement>) {
    setIsLoading(true)
    const file = event.target.files?.item(0)
    if (file) {
      broadcasts.updateAttachment(props.broadcastId, file)
        .then(() => {
          props.onUpload(file.name)
        })
        // @ts-ignore
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }
  return <div>
    {isLoading ? <>Uploaden..</> : <div className={"flex flex-col items-start"}>
      <SectionHeader>Bijlage toevoegen</SectionHeader>
      <div className={"relative w-full mt-4"}>
        <div className={"absolute top-0 left-0 w-full h-full bg-gray-100 flex items-center justify-center border-2 border-gray-400 border-dashed rounded-lg"}>
          {isLoading ? <div className={"mb-4"}>
            Uploaden...
          </div> : <>
            Klik hier om te uploaden, of sleep hier een bestand naar toe.
          </>}
        </div>
        <input type="file" className="w-full h-32 opacity-0" disabled={isLoading} onChange={upload} />
      </div>
    </div>}
  </div>
}

// eslint-disable-next-line no-unused-vars
const LocationSelect: FC<{value: number|null, onChange: (l: number|null) => void}> = ({value, onChange}) => {
  const { locations: locationsApi } = useApiCall(config)
  const { resource: locations } = useApiEndpoint(() => locationsApi.all())

  const locationNameMap = useMemo(() => {
    return locations?.reduce<{[locationId: string]: string}>((map, location) => {
      map[String(location.id)] = location.name
      return map
    }, {}) || {}
  }, [locations])

  const resolveLocationName = useCallback((location: Location): string => {
    return location.materializedPath
      .split('.')
      .map((id: string): number => Number(id))
      .map((id: number): string|undefined => locationNameMap[id])
      .filter(name => typeof name === "string")
      .join(' / ')
  }, [locationNameMap])

  return <Autocomplete
    options={locationNameMap}
    value={String(value ?? Object.keys(locationNameMap)[0])}
    onChange={(newKey) => onChange(Number(newKey))}
    label={"Locatie"}
  />
}

// eslint-disable-next-line no-unused-vars
const TeamSelect: FC<{value: number|null, onChange: (t: number|null) => void}> = ({value, onChange}) => {
  const { teams } = useTeamData()

  const { teams: teamsApi } = useApiCall(config)
  const { canListTeams } = usePermissions()
  const { resource: teamResource } = useApiEndpoint(() => teamsApi.myTeam(), canListTeams)
  const { resource: secondaryTeams } = useApiEndpoint(() => teamsApi.myTeams(), canListTeams)

  const teamMap = useTeamMap(teams, teamResource ?? null, secondaryTeams ?? [])
  const {canListAllTeams} = usePermissions()
  const teamOptionsMap: Map<number, [string, number]> = useMemo(() => {
    return new Map([
      [-1, ['Alle', 0]],
      ...teamMap
        .map<[Team, number]>(teams => {
          const team = teams.slice(-1)[0]
          return [team, teams.length]
        })
        .map<[number, [string, number]]>(([team, indentation]) => [team.id, [team.name, indentation]])
    ])
  }, [teamMap])
  const teamFilterOptions = useMemo(() => {
    return Array.from(teamOptionsMap).map(([id, [name, indentation]]: [number, [string, number]]): [string, string] => {
      return [id.toString(), (canListAllTeams ? "\xA0\xA0".repeat(indentation) : "") + name]
    })
  }, [teamOptionsMap, canListAllTeams])


  return <select onChange={(e) => onChange(e.target.value === '-1' ? null : Number(e.target.value))} value={value === null ? '-1' : value.toString()} className={"h-10 px-2 border border-gray-200 rounded-lg"}>
    {teamFilterOptions.map(([value, label], i) => {
      return <option key={i} value={value} label={label} />
    })}
  </select>
}

function BroadcastSendConfirmation(props: { broadcast: Partial<Broadcast>, onConfirm: () => Promise<void> }) {
  const [count, setCount] = useState<number>()
  const {broadcasts} = useApiCall(config)
  useEffect(() => {
    if (props.broadcast.id) {
      broadcasts.getSendQuote(props.broadcast.id)
        .then(count => setCount(count))
    }
  }, [])
  const [isSending, setIsSending] = useState(false)
  const [isSendingSpinner, setIsSendingSpinner] = useState(false)
  const [sendingProgress, setSendingProgress] = useState(0)
  function send() {
    const duration = 8 // seconds
    const interval = 50 // ms
    setIsSending(true)
    const timer = setInterval(() => {
      setSendingProgress(old => old + (interval / (duration * 1000) * 100))
    }, interval)
    setTimeout(() => {
      clearInterval(timer)
      sendNow()
    }, duration * 1000)
  }
  async function sendNow() {
    setIsSending(false)
    setIsSendingSpinner(true)
    await broadcasts.sendBroadcast(props.broadcast.id!)
    await props.onConfirm()
  }
  function cancel() {
    setIsSending(false)
    setSendingProgress(0)
  }
  if (isSending) {
    return <div className={"space-y-16 p-16 flex flex-col items-center"}>
      <div className={"w-full h-8 rounded-lg bg-gray-100 relative"}>
        <div className={"top-0 left-0 h-8 bg-blue-300 rounded-lg transition-all"} style={{ width: `${Math.floor(sendingProgress)}%`}}></div>
      </div>
      <Button type={'primary'} size={'sm'} onClick={() => cancel()} text={'Annuleren'} />
    </div>
  }
  if (isSendingSpinner) {
    return <div className={"space-y-16 p-16 flex flex-col items-center"}>
      <div className={"w-full h-8 rounded-lg flex justify-center items-center"}>
        <FontAwesomeIcon icon={faCircleNotch} className={"animate-spin size-16 p-6"}/>
      </div>
      <h1>Publicatie is aan het versturen. Dit kan even duren</h1>
    </div>
  }
  return <>
    <p>
      Je staat op het punt om een bericht te versturen naar { count !== undefined ? <strong>{count}</strong> : <FontAwesomeIcon icon={faCircleNotch} spin={true} className={'h-4 w-4'} />} {props.broadcast.target_type === "clients" ? "clienten" : "medewerkers"}.
    </p>
    <p>
      Weet je zeker dat je deze publicatie wilt versturen?
    </p>
    <Button type={'primary'} size={'sm'} onClick={() => send()} disabled={count === undefined} text={'Definitief versturen'} />
  </>
}
