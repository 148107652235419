import {AbstractRepository} from "../repository";

export type LearnModule = {
  id: string,
  name: string,
  description: string,
  mandatory: boolean,
  created_at: string
  updated_at: string
  components?: LearnComponent[]
}
export type LearnComponent = {
  id: string,
  name: string,
  order: number,
  created_at: string
  updated_at: string
  cards?: LearnCard[]
}
export type LearnCard = {
  id: string,
  type: string,
  order: number,
  title: string,
  description: string,
  media: string|null,
  properties: any,
  created_at: string
  updated_at: string
}


export default class LearnRepository extends AbstractRepository {
  /**
   * List all teams in the system
   */
  public async listModules(): Promise<Array<LearnModule>> {
    let response = await this.request<{ data: { [id: number]: LearnModule } }>('GET', 'learn/modules');
    return this.unpackDataCollectionResponse(response);
  }

  public async addModule(name: string, description: string, mandatory: boolean): Promise<LearnModule> {
    let r = await this.request<{ data: LearnModule }>('POST', 'learn/modules', {
      name,
      description,
      mandatory,
    });
    return r.data;
  }
  public async editModule(moduleId: string, name: string, description: string, mandatory: boolean): Promise<LearnModule> {
    let r = await this.request<{ data: LearnModule }>('POST', `learn/modules/${moduleId}`, {
      name,
      description,
      mandatory,
    });
    return r.data;
  }
  public async readModule(moduleId: string): Promise<LearnModule> {
    let r = await this.request<{ data: LearnModule }>('GET', `learn/modules/${moduleId}`);
    return r.data;
  }
  public async addComponent(moduleId: string, name: string): Promise<LearnComponent> {
    let r = await this.request<{ data: LearnComponent }>('POST', `learn/modules/${moduleId}/components`, {
      name,
    });
    return r.data;
  }
  public async listComponents(moduleId: string): Promise<Array<LearnComponent>> {
    let response = await this.request<{ data: { [id: number]: LearnComponent } }>('GET', `learn/modules/${moduleId}/components`);
    return this.unpackDataCollectionResponse(response);
  }
  public async readComponent(moduleId: string, componentId: string): Promise<LearnModule> {
    let r = await this.request<{ data: LearnModule }>('GET', `learn/modules/${moduleId}/components/${componentId}`);
    return r.data;
  }
  public async editComponent(moduleId: string, componentId: string, name: string): Promise<LearnComponent> {
    let r = await this.request<{ data: LearnComponent }>('POST', `learn/modules/${moduleId}/components/${componentId}`, {
      name,
    });
    return r.data;
  }

  public async editComponentsOrder(moduleId: string, components: LearnComponent[]): Promise<LearnComponent> {
    let r = await this.request<{ data: LearnComponent }>('POST', `learn/modules/${moduleId}/components/reorder`, {
      components: Object.fromEntries(components.map(component => [component.id, component.order])),
    });
    return r.data;
  }

  public async deleteComponent(moduleId: string, componentId: string): Promise<void> {
    await this.request<{}>('DELETE', `learn/modules/${moduleId}/components/${componentId}`);
  }

  public async addTextCard(moduleId: string, componentId: string, title: string, description: string): Promise<LearnCard> {
    let r = await this.request<{ data: LearnCard }>('POST', `learn/modules/${moduleId}/components/${componentId}/cards`, {
      type: 'text',
      title,
      description,
    });
    return r.data;
  }
  public async editTextCard(moduleId: string, componentId: string, cardId: string, title: string, description: string): Promise<LearnCard> {
    let r = await this.request<{ data: LearnCard }>('POST', `learn/modules/${moduleId}/components/${componentId}/cards/${cardId}`, {
      type: 'text',
      title,
      description,
    });
    return r.data;
  }
  public async addQuestionCard(moduleId: string, componentId: string, title: string, description: string, options: string[], hasCorrectOption: boolean, correctOption: number|number[]|null): Promise<LearnCard> {
    let r = await this.request<{ data: LearnCard }>('POST', `learn/modules/${moduleId}/components/${componentId}/cards`, {
      type: 'question',
      title,
      description,
      properties: {
        options,
        has_correct_option: hasCorrectOption,
        correct_option: correctOption,
      }
    });
    return r.data;
  }
  public async editQuestionCard(moduleId: string, componentId: string, cardId: string, title: string, description: string, options: string[], hasCorrectOption: boolean, correctOption: number|number[]|null): Promise<LearnCard> {
    let r = await this.request<{ data: LearnCard }>('POST', `learn/modules/${moduleId}/components/${componentId}/cards/${cardId}`, {
      type: 'question',
      title,
      description,
      properties: {
        options,
        has_correct_option: hasCorrectOption,
        correct_option: correctOption,
      }
    });
    return r.data;
  }
  public async addMediaCard(moduleId: string, componentId: string, title: string, description: string, file: File): Promise<LearnCard> {
    const formData = new FormData()
    formData.append('type', 'media')
    formData.append('title', title)
    formData.append('description', description)
    formData.append('media', file)
    let r = await this.formPostRequest<{ data: LearnCard }>(`learn/modules/${moduleId}/components/${componentId}/cards`, formData);
    return r.data;
  }
  public async editMediaCard(moduleId: string, componentId: string, cardId: string, title: string, description: string, file?: File): Promise<LearnCard> {
    const formData = new FormData()
    formData.append('type', 'media')
    formData.append('title', title)
    formData.append('description', description)
    if (file) {
      formData.append('media', file)
    }
    let r = await this.formPostRequest<{ data: LearnCard }>(`learn/modules/${moduleId}/components/${componentId}/cards/${cardId}`, formData);
    return r.data;
  }
  public async deleteCard(moduleId: string, componentId: string, cardId: string): Promise<void> {
    await this.request<{}>('DELETE', `learn/modules/${moduleId}/components/${componentId}/cards/${cardId}`);
  }

  public async editCardsOrder(moduleId: string, componentId: string, cards: LearnCard[]): Promise<LearnComponent> {
    let r = await this.request<{ data: LearnComponent }>('POST', `learn/modules/${moduleId}/components/${componentId}/cards/reorder`, {
      cards: Object.fromEntries(cards.map(card => [card.id, card.order])),
    });
    return r.data;
  }
}
