import { ContentContainer } from "@jane/lib/src/components/content/ContentContainer"
import { Button } from "@jane/lib/src/components/form/Button"
import { Paragraph } from "@jane/lib/src/components/Paragraph"
import {
  faCheck, faChevronLeft, faChevronRight,
  faCircleInfo,
  faEdit,
  faPencil,
  faPlus,
  faSave,
  faTimes,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import {useApiCall, useApiEndpoint} from "@jane/lib/src/api";
import {config} from "../config";
import { Loading } from "@jane/lib/src/components/Loading";
import {LearnComponent } from "@jane/lib/src/api/repositories/learn-repository";
import React, {FC, useEffect, useState} from "react";
import {useModal} from "../components/ModalProvider";
import {EditLearnModuleModal} from "../modals/EditLearnModuleModal";
import {useNavigate, useParams} from "react-router-dom";
import { SectionHeader } from "@jane/lib/src/components/content/SectionHeader";
import { SectionDivider } from "@jane/lib/src/components/content/SectionDivider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AddLearnComponentModal} from "../modals/AddLearnComponentModal";
import { Breadcrumbs } from "@jane/lib/src/components/content/Breadcrumbs";
import {EditLearnComponentModal} from "../modals/EditLearnComponentModal";
import {ConfirmationModal} from "../modals/ConfirmationModal";

export const LearnModuleDetailPage: React.FC = () => {
  const {learn} = useApiCall(config)
  const {moduleId} = useParams()
  const {resource, isLoading, reload} = useApiEndpoint(() => learn.readModule(moduleId!), moduleId !== undefined)
  const editModal = useModal({title: "Onderwerp bewerken", body: <EditLearnModuleModal module={resource!} onFinished={() => reload()} />})

  return <ContentContainer>
    <Loading loading={resource === undefined}>
      <Breadcrumbs currentPage={resource?.name??''} crumbs={[
        {label: 'Kennisbank', href: '/learn'}
      ]} />
      <div className={'flex items-center'}>
        <h1 className={"h-12 flex items-center text-2xl leading-tight font-bold"}>Onderwerp: {resource?.name}</h1>
        {resource?.mandatory && <span
          className={'ml-4 py-1 px-2 text-xs font-medium rounded border border-brand-on-primary-container'}>Verplicht</span>}
        <div className={"flex-1 flex justify-end"}>
          <Button type={'secondary'} size={'sm'} text={'Onderwerp bewerken'} icon={faPencil} onClick={() => editModal.open()}/>
        </div>
      </div>
      <div className={"flex justify-between"}>
        <div className={"max-w-2xl"}>
          <Paragraph>
            <pre className={"whitespace-pre-wrap"}>{resource?.description}</pre>
          </Paragraph>
        </div>
      </div>
      <SectionDivider />
      <section>
        <ComponentEditor moduleId={moduleId!} components={resource?.components ?? []} reload={reload} />
        {/*{resource?.components?.map(component => <ComponentCard moduleId={moduleId!} component={component} />)}*/}
      </section>
    </Loading>
  </ContentContainer>
}

const ComponentEditor: FC<{moduleId: string, components: LearnComponent[], reload: () => void}> = props => {
  const addModal = useModal({title: "Nieuw onderdeel", body: <AddLearnComponentModal moduleId={props.moduleId!} onFinished={() => props.reload()} />})
  const [mode, setMode] = useState<'view' | 'edit'>('view')
  const sortComponentsByOrder = (a: LearnComponent, b: LearnComponent) => a.order > b.order ? 1 : -1
  const [editingComponents, setEditingComponents] = useState(props.components?.sort(sortComponentsByOrder))
  const {learn} = useApiCall(config)
  const startEdit = () => {
    setEditingComponents([...(props.components || [])].sort(sortComponentsByOrder))
    setMode('edit');
  }
  const cancelEdit = () => {
    setEditingComponents([...(props.components || [])].sort(sortComponentsByOrder));
    setMode('view');
  }
  const saveEdit = async () => {
    const newlyOrderedComponents = editingComponents.map((component, i) => ({...component, order: i+1}))
    await learn.editComponentsOrder(props.moduleId, newlyOrderedComponents)
    props.reload()
    setMode('view');
  }
  const switchComponents = (a: number, b: number) => {
    setEditingComponents(old => {
      return old.map((component, i, allComponents) => {
        if (i === a) return {...allComponents[b]}
        if (i === b) return {...allComponents[a]}
        return {...component}
      })
    })
  }

  useEffect(() => {
    setEditingComponents(props.components?.sort(sortComponentsByOrder))
  }, [props.components]);

  return <section>
    <div className={"flex items-center justify-between mb-4"}>
      <div className={"flex items-center space-x-3"}>
        <div className={"-mb-4"}>
          <SectionHeader>Onderdelen</SectionHeader>
        </div>
        {mode === 'view' ? <>
          <Button type={'secondary'} size={'sm'} text={'Bewerken'} icon={faEdit} onClick={startEdit}/>
        </> : <>
          <Button type={'primary'} size={'sm'} text={'Volgorde opslaan'} icon={faSave} onClick={saveEdit}/>
          <Button type={'secondary'} size={'sm'} text={'Sluiten'} icon={faTimes} onClick={cancelEdit}/>
        </>}
      </div>

      <Button type={'primary'} size={'sm'} text={'Nieuw onderdeel'} icon={faPlus} onClick={() => addModal.open()}/>
    </div>
    <div className={"grid grid-cols-4 gap-8"}>
      {(mode === 'view' ? props.components.sort(sortComponentsByOrder) : editingComponents).map((component, i) => ({...component, order: i+1})).map((component, i, list) => {
        const isFirst = i === 0
        const isLast = i === list.length - 1
        return <ComponentPreview
          reload={props.reload}
          moduleId={props.moduleId}
          component={component}
          key={component.id}
          onMoveUp={!isFirst && mode === 'edit' ? () => switchComponents(i, i-1) : undefined}
          onMoveDown={!isLast && mode === 'edit' ? () => switchComponents(i, i+1) : undefined}
          canEdit={mode === 'edit'}
          canDelete={mode === 'edit'}
        />;
      })}
    </div>
  </section>
}

const ComponentPreview: FC<{
  moduleId: string,
  component: LearnComponent,
  canEdit: boolean,
  canDelete: boolean,
  onMoveUp?: () => void,
  onMoveDown?: () => void,
  reload: () => void
}> = props => {
  const navigate = useNavigate()
  const {learn} = useApiCall(config)
  const editModal = useModal({title: "Onderdeel bewerken", body: <EditLearnComponentModal moduleId={props.moduleId} component={props.component} onFinished={() => props.reload()} />})
  const deleteModal = useModal({title: "Onderdeel verwijderen", body: <ConfirmationModal text={'Weet je zeker dat je dit onderdeel wilt verwijderen?'} onConfirmed={async () => {
      await learn.deleteComponent(props.moduleId, props.component.id)
      props.reload()
    }} />})
  return <div
      className={`bg-brand-primary-container text-brand-on-primary-container border-2 ${(props.onMoveUp || props.onMoveDown) ? 'border-brand-primary' : 'border-brand-primary-container'} px-4 py-4 rounded-xl flex flex-col items-stretch relative`}>
    <div className={"flex items-center"}>
      <FontAwesomeIcon icon={faCircleInfo}/>
      <h3 className={'ml-3 font-medium leading-tight'}>{props.component.name}</h3>
    </div>
    <div className={"flex flex-col space-y-2 mb-4 mt-2 items-stretch flex-1 justify-end"}>
      <div className={"flex items-center justify-between"}>
        <span className={"font-light"}>Kaarten</span>
        <span className={"font-bold"}>{props.component.cards?.length ?? 0}</span>
      </div>
      <div className={"flex items-center justify-between ml-3"}>
        <span className={"font-light"}>Tekst</span>
        <span className={"font-bold"}>{props.component.cards?.filter(x => x.type === "text").length ?? 0}</span>
      </div>
      <div className={"flex items-center justify-between ml-3"}>
        <span className={"font-light"}>Beeld</span>
        <span
          className={"font-bold"}>{props.component.cards?.filter(x => x.type === "media").length ?? 0}</span>
      </div>
      <div className={"flex items-center justify-between ml-3"}>
        <span className={"font-light"}>Vragen</span>
        <span
          className={"font-bold"}>{props.component.cards?.filter(x => x.type === "question").length ?? 0}</span>
      </div>
    </div>
    <div className={"flex flex-col items-stretch space-y-2"}>
      {props.canEdit ? <Button type={'primary'} size={'xs'} text={'Bewerken'} icon={faPencil} onClick={editModal.open} /> : <></>}
      {props.canDelete ? <Button type={'primary'} size={'xs'} text={'Verwijderen'} icon={faTrash} onClick={deleteModal.open} /> : <></>}
      {props.canEdit || props.canDelete ? <></> : <Button type={'primary'} size={'xs'} text={'Kaarten bekijken'} onClick={() => navigate(`/learn/${props.moduleId}/${props.component.id}`)}/>}
    </div>
    {props.onMoveUp ? <button className={"h-6 w-6 absolute top-1/2 -translate-y-1/2 -left-3 bg-brand-primary text-brand-on-primary hover:shadow-lg rounded-full flex items-center justify-center"} onClick={props.onMoveUp}><FontAwesomeIcon icon={faChevronLeft} /></button> : <></>}
    {props.onMoveDown ? <button className={"h-6 w-6 absolute top-1/2 -translate-y-1/2 -right-3 bg-brand-primary text-brand-on-primary hover:shadow-lg rounded-full flex items-center justify-center"} onClick={props.onMoveDown}><FontAwesomeIcon icon={faChevronRight} /></button> : <></>}
  </div>
}